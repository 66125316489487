import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faHeart);

export default function Footer() {
  return <div className="footer-section">
    <div className="footer-text">
    <p className="footer-madewith">Made with</p> <p className="footer-heart"><FontAwesomeIcon icon={faHeart} /></p><p className="footer-using">using React</p>
    </div>
    
  </div>;
}
