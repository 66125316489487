import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles.css";
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);


var randomColorIndex = Math.floor(Math.random() * 4);

export let accentTheme = "accent-" + randomColorIndex.toString();
export let accentThemeHover = "accenthover-" + randomColorIndex.toString();

console.log(accentTheme);